<template>
    <div id="news">
        <div class = "bannerBox">
            <div class="banner">
                <swiper class="swiper gallery-top" ref="swiperTop" :options="swiperOptions">
                    <swiper-slide v-for="(item, index) in banners" :key="index">
                        <img :src="staticPath + (isEn ? item.enImageUrl : item.cnImageUrl)" alt />
                    </swiper-slide>
                </swiper>
                <swiper class="swiper gallery-thumbs" ref="swiperThumbs" :options="swiperOptionThumbs">
                    <swiper-slide v-for="(item, index) in banners" :key="index">
                        <!--                    <span>{{ item.type === 0 ? '头条' : item.type === 1 ? '咨询' : '新闻' }}</span>-->
                        {{ isEn ? item.enTitle : item.cnTitle }}
                    </swiper-slide>
                    <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                </swiper>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>
<script>
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.css";
    import {urls, staticPath} from '@/lib/common'
    export default {
        name: "News",
        components: {
            swiper,
            swiperSlide
        },
        data() {
            return {
                staticPath: staticPath,
                swiperOptions: {
                    grabCursor: false,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    }
                },
                swiperOptionThumbs: {
                    spaceBetween: 20,
                    direction: 'vertical',
                },
                banners: [],
                lists: {},
                videoIdx: 0,
                overlay: false,
                contId: '',
                isEn: false,

            };
        },
        watch: {
            "$i18n.local": function(val) {
                this.isEn = val === 'en' ? true : false
            }
        },
        methods: {
            playVideo(index) {
                this.videoIdx = index;
                this.overlay = true;
            },
            getBannerList() {
                this.$http.get(urls.getBannerList + this.contId).then(res => {
                    if (res.data.status === 200) {
                        this.banners = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        mounted() {
            this.contId = window.atob(decodeURIComponent(this.$route.query.p))
            this.getBannerList()

            this.$nextTick(() => {
                const swiperTop = this.$refs.swiperTop.swiper
                const swiperThumbs = this.$refs.swiperThumbs.swiper
                swiperTop.controller.control = swiperThumbs
                swiperThumbs.controller.control = swiperTop
            })
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #news {
        background: url("../../assets/images/news/news_bg.png") no-repeat center center;
        background-size: 100%;
        &::before {
            content: '';
            display: block;
            width: 5.7vw;
            height: 23.4vw;
            background: url("../../assets/images/1097.png") no-repeat center center;
            background-size: 100% 100%;
            position: absolute;
            top: 60vw;
            left: 0;
        }
        .bannerBox{
            overflow:hidden;
            width: 100%;
            height: 600px;
            position: relative;
            .banner {
                position: absolute;
                top:0;
                left: 50%;
                margin-left: -960px;
                width: 1920px;
                height: 600px;
                .gallery-thumbs {
                    width: 1200px;
                    bottom: 24px;
                    height: 75px;
                    line-height: 75px;
                    margin: -50px auto;
                    color: #fff;
                    background: rgba(0, 0, 0, 0.75);
                    padding: 0 20px;
                    box-sizing: border-box;
                    border-radius: 10px 10px 0 0 ;
                    .swiper-slide {
                        font-size: 18px;
                        >span {
                            display: inline-block;
                            padding: 5px 10px;
                            color: #fff;
                            border-radius: 4px;
                            background: linear-gradient(to bottom, #FF4B7C, #DC000C);
                        }
                    }
                    @{deep} .swiper-pagination-bullets {
                        width: 6vw;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .swiper-pagination-bullet-active {
                            width: 2vw;
                            height: 1vw;
                            border-radius: 2vw;
                        }
                    }
                    @{deep} .swiper-pagination-bullet {
                        background-color: #F2FCFF;
                        width: 1vw;
                        height: 1vw;
                    }
                }
            }
        }
            
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #news{
            background:none;
            .bannerBox{
                height: auto;
                .banner{
                    position:relative;
                    top:0;
                    left:0;
                    width: 100%;
                    margin-left: 0;
                    .fontFn(170,height);
                    margin-bottom: 6%;
                    .gallery-thumbs{
                        display: none;
                    }
                }
                &::before{
                    background:none;
                }     
            }   
            &::before{
                content: none;
            }
        }

        .swiper-slide{
            .fontFn(170,height);
            position: relative;
            @{deep} img{
                width: auto;
                .fontFn(170,height);
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%,-50%);
                -webkit-transform: translate(-50%,-50%);
                -moz-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                -o-transform: translate(-50%,-50%);
            }
        }
    }
</style>